
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import { BContainer, BRow, BCol } from 'bootstrap-vue';
import { LocaleMessage } from 'vue-i18n';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomBadge, { BadgeVariant } from '@/components/atoms/AtomBadge.vue';
import MoleculeCard, { CardVariant } from '@/components/molecules/cards/MoleculeCard.vue';
import MoleculeCardUser, { CardUserVariant } from '@/components/molecules/cards/MoleculeCardUser.vue';
import RideIntentModel from '@/models/ride-intent/RideIntentModel';
import { RideUserModel } from '@/models/ride/RideModel';
import { formatTime } from '@/common/DateFormatter';

export enum RideDetailsCardVariant {
  START = 'tg-rdc-start',
  START_DISABLED = 'tg-rdc-start--disabled',
  START_PAST = 'tg-rdc-start--past',
  STOP = 'tg-rdc-stop',
  STOP_DISABLED = 'tg-rdc-stop--disabled',
  STOP_PAST = 'tg-rdc-stop--past',
  END = 'tg-rdc-end',
  END_DISABLED = 'tg-rdc-end--disabled',
  END_PAST = 'tg-rdc-end--past',
}

export interface WalkDistanceModel {
  distanceInMeters: number;
  durationInMinutes: number;
}

export default Vue.extend({
  name: 'OrganismRideDetailsCard',
  components: {
    BContainer,
    BRow,
    BCol,
    AtomSvgIcon,
    AtomBadge,
    MoleculeCard,
    MoleculeCardUser,
  },
  props: {
    variant: {
      type: String as PropType<RideDetailsCardVariant>,
      required: true,
      validator: (val: RideDetailsCardVariant) => Object.values(RideDetailsCardVariant).includes(val),
    },
    stop: {
      type: Object as PropType<RideIntentModel>,
      required: true,
    },
    users: {
      type: Array as PropType<RideUserModel[]>,
      default: () => [],
    },
    isPassenger: {
      type: Boolean,
      default: true,
    },
    walkDistance: {
      type: Object as PropType<WalkDistanceModel>,
      default: () => ({} as WalkDistanceModel),
    },
  },
  methods: {
    formatTime,
    navigateToUser(id: string) {
      if (id === this.getUserId) {
        this.$router.push({ name: 'Profile' });
      } else {
        this.$router.push({
          name: 'ProfileUser',
          params: { id },
        });
      }
    },
  },
  computed: {
    ...mapGetters('user', ['getUserId']),
    getHeading(): LocaleMessage {
      switch (this.variant) {
        case RideDetailsCardVariant.START:
        case RideDetailsCardVariant.START_PAST:
          return this.$t('home.detailView.rideStart');
        case RideDetailsCardVariant.START_DISABLED:
          return this.$t('home.detailView.earliestDeparture');
        case RideDetailsCardVariant.END:
        case RideDetailsCardVariant.END_PAST:
          return this.$t('home.detailView.rideEnd');
        case RideDetailsCardVariant.END_DISABLED:
          return this.$t('home.detailView.latestArrival');
        default:
          return this.$t('home.detailView.rideStop');
      }
    },
    getCircleClasses(): object {
      return {
        circle: true,
        filled: this.ALL_START_VARIANTS.includes(this.variant),
        'filled-color': this.ALL_END_VARIANTS.includes(this.variant),
      };
    },
    displayWalkDistance(): boolean {
      return this.isPassenger && Object.keys(this.walkDistance).length > 0;
    },
  },
  data: () => ({
    BadgeVariant,
    CardVariant,
    RideDetailsCardVariant,
    CardUserVariant,
    ALL_DISABLED_VARIANTS: [
      RideDetailsCardVariant.START_DISABLED,
      RideDetailsCardVariant.START_PAST,
      RideDetailsCardVariant.STOP_DISABLED,
      RideDetailsCardVariant.STOP_PAST,
      RideDetailsCardVariant.END_DISABLED,
      RideDetailsCardVariant.END_PAST,
    ],
    ALL_START_VARIANTS: [
      RideDetailsCardVariant.START,
      RideDetailsCardVariant.START_DISABLED,
      RideDetailsCardVariant.START_PAST,
    ],
    ALL_STOP_VARIANTS: [
      RideDetailsCardVariant.STOP,
      RideDetailsCardVariant.STOP_DISABLED,
      RideDetailsCardVariant.STOP_PAST,
    ],
    ALL_END_VARIANTS: [
      RideDetailsCardVariant.END,
      RideDetailsCardVariant.END_DISABLED,
      RideDetailsCardVariant.END_PAST,
    ],
  }),
});
